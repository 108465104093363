<template>


    <!-- BEGIN: Content-->
    <div class="content-body">
        <!-- full job description -->
        <!-- Bordered table start -->
        <div class="row" id="table-bordered">
            <div class="col-12">
                <base-card-plain :showFooter="false">
                    <template #header>
                        <div class="col-6 col-md-6 col-lg-6 col-sm-12 mb-1">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Add city, country or continents " aria-describedby="button-addon2" />
                            </div>
                        </div>
                        <base-card-drop-down>
                            <template #title>
                                Filter options
                            </template>
                            <template #default>
                                <a class="dropdown-item" href="#">
                                    <i class="bi bi-check bs-icon-tiny" ></i>
                                    Sort by category
                                </a>
                                <a class="dropdown-item" href="#">
                                    <i class="bi bi-x bs-icon-tiny" ></i>
                                    Sort by status
                                </a>

                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">
                                    <i class="bi bi-pencil bs-icon-tiny" ></i>
                                    all
                                </a>
                                <a class="dropdown-item" href="#">
                                    <i class="bi bi-pencil bs-icon-tiny" ></i>
                                    active
                                </a>
                                <a class="dropdown-item" href="#">
                                    <i class="bi bi-pencil-square bs-icon-tiny" ></i>
                                    inactive
                                </a>
                            </template>
                        </base-card-drop-down>
                    </template>
                    <template #default>

                            <table class="table table-bordered table-responsive">
                                <thead>
                                    <tr>
                                        <th>Organization</th>
                                        <th class="tiny-td-col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="">
                                    <tr class="table-hover">
                                        <td>
                                            <span class="fw-bold"> Frontend </span>
                                            <base-badge  badgeColor="primary" >
                                                active
                                            </base-badge>
                                        </td>
                                        <td>
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                    <i class="bi bi-three-dots bs-icon-small" ></i>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-pencil-square bs-icon-small" ></i>
                                                        <span> Edit</span>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-archive bs-icon-small" ></i>
                                                        <span> Disable</span>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-trash bs-icon-small" ></i>
                                                        <span> Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="table-hover">
                                        <td>
                                            <span class="fw-bold"> How many eggs are in the create ? </span>
                                            <base-badge  badgeColor="warning" >
                                                inactive
                                            </base-badge>
                                        </td>
                                        <td>
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                    <i class="bi bi-three-dots bs-icon-small" ></i>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-pencil-square bs-icon-small" ></i>
                                                        <span> Edit</span>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-archive bs-icon-small" ></i>
                                                        <span> Disable</span>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-trash bs-icon-small" ></i>
                                                        <span> Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="table-hover">
                                        <td>
                                            <span class="fw-bold"> How many eggs are in the create ? </span>
                                            <base-badge  badgeColor="primary" >
                                                Backend
                                            </base-badge>
                                        </td>
                                        <td>
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                    <i class="bi bi-three-dots bs-icon-small" ></i>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-pencil-square bs-icon-small" ></i>
                                                        <span> Edit</span>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-archive bs-icon-small" ></i>
                                                        <span> Disable</span>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-trash bs-icon-small" ></i>
                                                        <span> Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <base-pagination>
                            </base-pagination>
                    </template>
                </base-card-plain>
            </div>
        </div>
        <!-- Bordered table end -->
        <!-- full job description -->



    </div>
    <!-- END: Content-->


</template>

<style scoped>
.multi-icon-spacer{
    padding:2px;
}
</style>
