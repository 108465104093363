<template>
    <!-- form -->
    <form class="validate-form">
    <!-- core job detail -->
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Add new category</h4>

        </template>
        <template #default>
                <div class="row">

                    <!-- header section -->
                    <div class="d-flex">
                        <div class="row">
                            <div class="col-4 col-lg-4 col-sm-12"></div>
                            <div class="col-4 col-lg-4 col-sm-12">
                                <div class="card btn-outline-secondary">
                                    <base-image
                                         imgName="04.jpg"
                                         imgGeo="slider"
                                         imgAlt="Card image cap"
                                         imgClass="card-img-top"
                                         >
                                     </base-image>
                                    <div class="card-body">
                                        <label for="account-upload" class="btn btn-sm btn-outline-secondary mb-75 me-75">Upload cover image</label>
                                        <input type="file" id="account-upload" hidden accept="image/*" />
                                        <p>Jpg or png. Max size of 800kB</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 col-lg-4 col-sm-12"></div>
                        </div>
                    </div>
                    <!--/ header section -->

                    <div class="col-12 col-sm-12 mt-2">
                        <div class="mb-2">
                            <label class="form-label" for="account-birth-date">Category name</label>
                            <input type="text" class="form-control" placeholder="Organization name" id="account-birth-date" name="dob" />
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-1 mt-1">
                            <label class="form-label" for="accountTextarea">Category description</label>
                            <textarea class="form-control" id="accountTextarea" rows="2" placeholder="Enter question"></textarea>
                        </div>
                    </div>


                    <div class="col-12">
                        <base-button btnColor="primary" >Save category</base-button>
                    </div>
                </div>
        </template>
    </base-card-plain>
    <!--Core job detail -->

    </form>
    <!--/ form -->
</template>

<script>


export default{
    props:{
        useExistingJD:{
            default:false,
            type: Boolean,
            required: false
        }
    }
}


</script>
